import store from '@/store';
import { apiService } from './ApiService';
import { setStoreLocationDetails } from '@/store/features/storeLocationSlice';
import { setCookie, deleteCookie } from 'cookies-next';

const getStoreLocation = async (pin: string, isCity?: boolean) => {
  deleteCookie('city');
  deleteCookie('pincode');
  deleteCookie('state');
  localStorage.removeItem('location');
  const response: any = await apiService.get(
    `getStoreLocation?location=${pin}`,
    '',
    '',
    false
  );
  if (Object.keys(response?.data).length > 0) {
    const rows = response?.data?.rows;
    if (rows.length > 0) {
      rows.forEach((address: any) => {
        if (address?.store_address?.length > 0) {
          const show = address?.store_address?.split(',');
          const len = show?.length;
          address.display =
            show[len - 4] +
            ',' +
            show[len - 3] +
            ',' +
            show[len - 2] +
            ',' +
            show[len - 1];
        }
      });
      if (!isCity) {
        const pincode = rows[0]?.pincode;
        const city = rows[0]?.city;
        const state = rows[0]?.state;
        const pcity = city ? city[0]?.toUpperCase() + city?.substring(1) : '';
        city && setCookie('city', pcity);
        state && setCookie('state', state);
        pincode && setCookie('pincode', pincode);
        localStorage.setItem('location', JSON.stringify(rows));
        store.dispatch(setStoreLocationDetails(response.data));
      }
    }
  } else setCookie('pincode', pin);
  return response;
};

export const storeLocationService = {
  getStoreLocation,
};
