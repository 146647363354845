import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { getCookie } from 'cookies-next';
import { useSelector } from 'react-redux';

import { RootState } from '@/store';
import styles from './style.module.scss';
const WsButton = dynamic(() => import('@/components/common/WsButton'));
import { storeLocationService } from '@/services/storeLocationService';

const DiscountStrip: React.FC = () => {
  const city = getCookie('city');
  const pin = getCookie('pincode');
  const [loading, setLoading] = useState(false);
  const [pinText, setPinText] = useState<string>('');
  const [pinError, setPinError] = useState(false);
  const [isTextEnable, setEnabletext] = useState(false);
  const [isShowPinMenu, setShowPinMenu] = useState(false);
  const [isShowRelateStore, setShowRelateStore] = useState(false);
  const [pinLable, setPinLable] = useState<string>('Enter Pincode');
  const [placeHolder, setPlaceHolder] = useState<string>('313001,Udaipur');
  const [location, setLocation] = useState<string[]>([]);
  const [showView, setShowView] = useState<any>({});
  const stores = useSelector((state: RootState) => state.stores);

  // const [latitude, setLatitude] = useState<string>('');
  // const [longitude, setLongitude] = useState<string>('');
  useEffect(() => {
    if (pin && city) {
      setPinLable(pin + ' , ' + city);
      setPlaceHolder(pin + ', ' + city);
      if (localStorage.location) {
        const location = JSON.parse(localStorage.location);
        if (location?.length > 0) {
          if (location?.length > 5) {
            setShowView({
              show: true,
              href: '/furniture-store-' + location[0]?.city?.toLowerCase(),
            });
            location.splice(6, location.length - 1);
            setLocation(location);
          } else setLocation(location);
          setShowRelateStore(true);
        }
      }
    } else {
      setLocation([]);
      setEnabletext(false);
      setPinLable('Enter Pincode');
      setPlaceHolder('313001,Udaipur');
    }
    // getLocation();
  }, [stores]);

  // const getLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.permissions
  //       .query({ name: 'geolocation' })
  //       .then((permissionStatus) => {
  //         if (permissionStatus.state === 'denied') {
  //           console.log('🚀 ~ .then ~ permissionStatus:', permissionStatus);
  //         } else {
  //           navigator.geolocation.getCurrentPosition(
  //             (success: any) => {
  //               console.log('🚀 ~ .then ~ success:', success);
  //               // setLatitude(success.coords.latitude);
  //               // setLongitude(success.coords.longitude);
  //             },
  //             (error) => {
  //               console.log(error);
  //             }
  //           );
  //         }
  //       });
  //   } else {
  //     alert('Geolocation is not supported in your browser.');
  //   }
  // };

  const handlePinMenu = (e: any) => {
    e.preventDefault();
    setPinError(false);
    setShowPinMenu(!isShowPinMenu);
    setPinText('');
    if (pin && city) setEnabletext(false);
    else setEnabletext(true);
  };
  const handlePinText = (e: any) => {
    e.preventDefault();
    const text = e.target.value;
    setLoading(false);
    setPinError(false);
    if (pinText?.length < 6 || text.length < 6) setPinText(text);
  };
  const handleEnableText = () => {
    setPinText('');
    setPlaceHolder('');
    setLoading(false);
    setPinError(false);
    setEnabletext(!isTextEnable);
  };

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (pinText.length === 6) {
        setEnabletext(false);
        const res = await storeLocationService.getStoreLocation(pinText);
        if (res.code == 200 && res?.data?.rows?.length > 0) {
          setShowRelateStore(true);
          setPinText('');
        } else {
          setPinError(true);
          setLocation([]);
          setPlaceHolder('');
          setEnabletext(true);
          setShowRelateStore(true);
          setPinLable('Enter Pincode');
        }
      } else setPinError(true);
    } catch (error) {
      setPinError(true);
      console.log('handleSubmit ~ error:', error);
    }
    setLoading(false);
  };
  return (
    <div className={styles.topheaderSection}>
      <div className="container-1240">
        <div className={styles.topheaderInner}>
          <ul className={styles.topheaderLeft}>
            <li>
              <div className={styles.topLocation}>
                <Image
                  src="/images/header/location-icon.svg"
                  alt="media"
                  width={23}
                  height={23}
                  loading="eager"
                />
                <p>
                  <span> Find a Store </span> - {pinLable}
                </p>
                <Link
                  href="#"
                  className={styles.editIcon}
                  onClick={handlePinMenu}
                >
                  <Image
                    src="/images/header/topedit-img.svg"
                    alt="media"
                    width={18}
                    height={18}
                    loading="eager"
                  />
                </Link>
              </div>
              {isShowPinMenu && (
                <div className={styles.topheaderapply}>
                  <Link
                    href="#"
                    className={styles.Closeapply}
                    onClick={handlePinMenu}
                  >
                    <Image
                      src="/images/header/apply-close.svg"
                      alt="media"
                      width={25}
                      height={25}
                      loading="eager"
                    />
                  </Link>
                  <form onSubmit={handleSubmit}>
                    <div className={styles.topapplycard}>
                      <label className={styles.field__label}>Pincode</label>
                      <input
                        id="search-user"
                        className={styles.formControl}
                        type="number"
                        disabled={!isTextEnable}
                        value={pinText}
                        onChange={handlePinText}
                        placeholder={placeHolder}
                      />
                      {isTextEnable && (
                        <WsButton
                          type="submit"
                          className={styles.applybtn}
                          isLoading={loading}
                        >
                          Apply
                        </WsButton>
                      )}
                      {!isTextEnable && (
                        <button
                          type="button"
                          className={styles.applybtn}
                          onClick={handleEnableText}
                        >
                          Change
                        </button>
                      )}
                    </div>
                    {pinError && (
                      <p className={styles.pinError}>
                        {pinText?.length < 6
                          ? 'Please enter a valid Pincode'
                          : 'Delivery option not available on requested area.'}
                      </p>
                    )}
                  </form>
                  {isShowRelateStore && !pinError && location?.length > 0 && (
                    <div className={styles.relatestore}>
                      <b>Nearest Woodenstreet Stores</b>
                      <ul className={styles.storeBox}>
                        {showView?.show && (
                          <li className={styles.storesort} data-sort="100">
                            <a
                              id="store1000"
                              target="_blank"
                              href={showView.href}
                            >
                              View All
                            </a>
                          </li>
                        )}
                        {location?.map(
                          (place: any, i: number) =>
                            place?.latitude &&
                            place?.longitude && (
                              <li className={styles.storesort} key={i}>
                                <Link
                                  target="_blank"
                                  id="store309"
                                  href={'/' + place.url}
                                >
                                  <Image
                                    src="/images/header/location.svg"
                                    alt="media"
                                    width={25}
                                    height={25}
                                    loading="eager"
                                  />
                                  <span>
                                    {place?.display?.trim() ??
                                      place?.store_address?.trim()}
                                  </span>
                                </Link>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </li>
          </ul>
          <ul className={styles.topheaderRight}>
            <li>
              <Link
                href={'tel:+91-9314444747'}
                className={styles.topheaderLinks}
                target="_blank"
              >
                <Image
                  src="/images/header/top-phone.svg"
                  alt="media"
                  width={15}
                  height={15}
                  loading="eager"
                />
                <p>+91-9314444747</p>
              </Link>
            </li>
            <li>
              <Link
                href="/track-your-order"
                className={styles.topheaderLinks}
                target="_blank"
              >
                <Image
                  src="/images/header/TrackOrder.svg"
                  alt="media"
                  width={23}
                  height={23}
                  loading="eager"
                />
                <p> Track Order</p>
              </Link>
            </li>
            <li>
              <Link
                href="/help-center"
                className={styles.topheaderLinks}
                target="_blank"
              >
                <Image
                  src="/images/header/help-center.svg"
                  alt="media"
                  width={17}
                  height={17}
                  loading="eager"
                />
                <p> Help Center</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DiscountStrip;
